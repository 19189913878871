<template>
    <OModal ref="createActivitiesFromComponentDialog" >
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Activity Settings") }}
                        </h4>
                        <div v-if="isExecutingProc" class="spinner-border spinner-border-sm" style="0.35rem;" role="status">
                            <span class="visually-hidden">{{ $t('Processing...') }}</span>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row">
                    <div class="row pl-3">
                        <div class="col-6">
                            <label>{{$t("Org.unit")}}:</label>        
                            <OrgunitsLookup 
                                textInput
                                :bind="sel=>{OrgUnit_ID = sel.ID; OrgUnit = sel.Name;}"
                                class="form-control form-control-sm "
                                v-model="OrgUnit" />
                        </div>
                        <div class="col-6"> 
                            <label>{{ $t("Sub Project") }}</label>            
                            <SubProjectsLookup 
                                textInput
                                :OrgUnitId="OrgUnit_ID"
                                :bind="sel=>{SubProject_ID = sel.ID; SubProject = sel.Name;}"
                                class="form-control form-control-sm " 
                                :textInputValue="SubProject" />
                        </div>
                        <div class="col-6">
                            <label>{{ $t("Construction Phase") }}</label>
                            <ConstructionPhasesLookup 
                                textInput
                                :OrgUnitId="OrgUnit_ID"
                                :bind="sel=>{ConstructionPhase_ID = sel.ID; ConstructionPhase = sel.Name;}"
                                class="form-control form-control-sm "
                                :textInputValue="ConstructionPhase" />
                        </div>
                        <div class="col-6">
                            <label>{{ $t("Shutdown") }}</label>
                            <ShutdownsLookup 
                                textInput
                                :OrgUnitId="OrgUnit_ID"
                                :bind="sel=>{Shutdown_ID = sel.ID; Shutdown = sel.Name;}"
                                class="form-control form-control-sm "
                                :textInputValue="Shutdown" /> 
                        </div>
                        <div class="col-6">
                            <label>{{ $t("Milestone") }}</label>                                                               
                            <MilestonesLookup 
                                textInput
                                :bind="sel=>{Milestone_ID = sel.ID; Milestone = sel.Name;}"
                                class="form-control form-control-sm "
                                :textInputValue="Milestone" /> 
                        </div>
                        <div class="col-6">
                            <label>{{ $t("Package") }}</label>
                            <PackageLookup 
                                textInput
                                :OrgUnitId="OrgUnit_ID"
                                :bind="sel=>{Package_ID = sel.ID; Package = sel.Name;}"
                                class="form-control form-control-sm "
                                :textInputValue="Package" /> 
                        </div>
                        <div class="col-12">
                            <label>{{$t("Description")}}</label>   
                            <input type="text" class="form-control " v-model="desc">
                        </div>
                        <div class="col-12">
                            <label class="">
                            <input v-model="inclObjectName" class="form-check-input" type="checkbox">
                                {{$t("Include Object Name in Activity Name") }}
                            </label>
                        </div>
                        <div class="col-12">
                            <label class="">
                            <input v-model="inclObjectDesc" class="form-check-input" type="checkbox">
                                {{$t("Include Object Description in Activity Name")}}
                            </label>
                        </div>
                        <div class="col-12">
                            <label class="">
                            <input v-model="useOrgUnitFromObj" class="form-check-input" type="checkbox">
                                {{ $t("Use org.unit from object for the new activities") }}
                            </label>
                        </div>
                    </div>
                </div>
                            
                <div class="modal-footer">
                    <div style="display:inline;font-size:small;" class="me-2" v-if="selectedObjectsCount===0">
                        <div class="text-danger">{{ $t("No objects selected") }}</div>
                    </div>
                    <button class="btn btn-outline-primary" :disabled="selectedObjectsCount===0" @click="createActs">{{$t("Create")}}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
import { ref, defineExpose, computed, watch } from 'vue';
import OModal from 'o365.vue.components.Modal.vue'; 
import { userSession } from 'o365.modules.configs.ts';
import OrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue'
import SubProjectsLookup from "complete.vue.components.SubProjectsLookup.vue";
import ConstructionPhasesLookup from "complete.vue.components.ConstructionPhasesLookup.vue";
import ShutdownsLookup from "complete.vue.components.ShutdownsLookup.vue";
import MilestonesLookup from "complete.vue.components.MilestonesLookup.vue";
import PackageLookup from "complete.vue.components.PackageLookup.vue";
import alert from 'o365.controls.alert.ts';
import context from "o365.modules.Context.ts";

const emit = defineEmits(["ActivitiesCreated"]);
const OrgUnit_ID = ref(null);
const OrgUnit = ref();
const SubProject = ref();
const SubProject_ID = ref(null);
const Shutdown_ID = ref(null);
const Shutdown = ref();
const ConstructionPhase_ID = ref(null);
const ConstructionPhase = ref();
const Milestone_ID = ref(null);
const Milestone = ref();
const Package_ID = ref(null);
const Package = ref(); 
const procSubmit = getOrCreateProcedure({ id: "procSubmit" + crypto.randomUUID(), procedureName: "astp_Complete_AddActivityFromComponentType" });
const createActivitiesFromComponentDialog = ref();
const isExecutingProc = ref(false);
const inclObjectName = ref(false); 
const inclObjectDesc = ref(false); 
const useOrgUnitFromObj = ref(false); 
const desc = ref();

async function createActs() {
    const preparedRows = [];
    if (props.objectsDataObject?.selectionControl?.selectedRows?.length>0) {
        const ids = await props.objectsDataObject.selectionControl.getSelectedRows();
        ids.forEach(sel => {
            if (sel.ID !== null) preparedRows.push([sel.ID]);
        });
        createActivities(preparedRows);
    }
    else if (props.objectID) {
        preparedRows.push([props.objectID]);
        createActivities(preparedRows);
    }
}

defineExpose({ show });


const props = defineProps({
    objectsDataObject: {
        type: Object,
        required: false
    },
    objectID: "Number"
});

const selectedObjectsCount = computed(() => props.objectsDataObject?.selectionControl?.selectedRows?.length);

async function show() {
    
    OrgUnit_ID.value = context.id;
    OrgUnit.value = context.orgUnit;
    
    createActivitiesFromComponentDialog.value.show();
}

async function createActivities(ids) {
    isExecutingProc.value = true;
    await procSubmit.execute({
        Objects: ids,
        IncludeObjectName: inclObjectName.value,
        IncludeObjectDescription: inclObjectDesc.value,
        OrgUnit_ID: OrgUnit_ID.value,
        SubProject_ID: SubProject_ID.value,
        ConstructionPhase_ID: ConstructionPhase_ID.value,
        Shutdown_ID: Shutdown_ID.value,
        Milestone_ID: Milestone_ID.value,
        Package_ID: Package_ID.value,
        Description: (desc.value ? desc.value : null),
        UseObjectsOrgUnit: useOrgUnitFromObj.value 
    }).then(
        (response) => { 
            if(response.Table.length>0) {
                var vMessage = $t("Generated the following activities:") + " \n\n";
                response.Table.forEach(function(row,i) { 
                    vMessage += row.ID + " - " + row.Name + "\n";
                });  
                alert(vMessage, 'success', {autohide: true, delay: 3000});
                emits("ActivitiesCreated", response.Table[0]);
                createActivitiesFromComponentDialog.value.hide();
            } else {
                alert($t('No activities generated.'), 'danger', { autohide: true, delay: 3000});
            }
        }
    ).finally(() => {
        isExecutingProc.value = false;
    });    
}

</script>